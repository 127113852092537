<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ form.title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              @click="$router.go(-1)"
            >
              <i data-feather="chevron-left" />
            </a>
            <span
              class="ms-50"
            >
              <a
                class="btn btn-icon btn-primary"
                @click="$store.dispatch('modals/toggleReminderForms', true)"
              >
                <i data-feather="send" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">

        <div class="row">
          <div class="col-12">

            <div
              v-if="stats"
              class="card"
            >
              <div
                v-b-toggle="`collapseChapters`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Stats</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseChapters`"
                class="mt-1"
              >
                <div class="card-content ">
                  <div class="row grants__stats">
                    <div class="col-md-3">
                      <div class="card mb-0">
                        <div class="card-header pb-0 mt-0">
                          <h5>Guests</h5>
                        </div>
                        <div class="card-body pb-0">
                          <p class="display-5 mb-0">
                            {{ stats.guests.data.length }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card mb-0">
                        <div class="card-header pb-0 mt-0">
                          <h5>Answers</h5>
                        </div>
                        <div class="card-body pb-0">
                          <p class="display-5 mb-0 text-success">
                            {{ answereds }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card mb-0">
                        <div class="card-header pb-0 mt-0">
                          <h5>Pending</h5>
                        </div>
                        <div class="card-body pb-0">
                          <p class="display-5 mb-0 text-danger">
                            {{ pendings }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="card mb-0">
                        <div class="card-header pb-0 mt-0">
                          <h5>Deadline</h5>
                        </div>
                        <div class="card-body pb-0">
                          <p class="display-5 mb-0">
                            {{ form.end_date }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <hr>
                    <h4>Reminders</h4>
                    <p
                      v-for="reminder, index in stats.reminders"
                      :key="index"
                    >{{ index + 1 }}. {{ reminder.date_minute }} to: <span
                      class="text-danger"
                    >{{ reminder.user_count }} guests</span></p>
                  </div>
                </div>
              </b-collapse>

            </div>

            <CompleteTable
              :sorted-field="sortedField"
              :data="formUsers"
              :sort-mapping="sortMapping"
              :title="'guests'"
              :total="formUsersTotal"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    left
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="exportData(form.id, 'answers', field.id)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> Download answers
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="active = field, $store.dispatch('modals/toggleReminderForms', true)">
                        <i
                          class="me-50"
                          data-feather="bell"
                        /> Send reminder
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters />
            </CompleteTable>
          </div>
          <SendReminderForms
            :form="form"
            :user="active"
            @removeUser="active = null"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import Vue from 'vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import SendReminderForms from '../../partials/offcanvas/SendReminderForms.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    SendReminderForms,
    BCollapse,
  },
  data() {
    return {
      active: null,
      sortedField: 'Name form',
      defaultFields: [
        {
          name: 'Guest',
          checked: true,
          order: 1,
        },
        {
          name: 'Date',
          checked: true,
          order: 2,
        },
        {
          name: 'Answer',
          checked: true,
          order: 3,
        },
        {
          name: 'Send reminders',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Guest: 'name',
        Date: 'date',
        Answer: 'has_answer',
        'Send reminders': 'reminders',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      formUsers: 'requestForms/guests',
      stats: 'requestForms/stats',
      formUsersTotal: 'requestForms/guestsTotal',
      filters: 'filters/filters',
      form: 'requestForms/item',
    }),
    pendings() {
      return this.stats ? this.stats.guests.data.filter(e => !e.has_answers).length : 0
    },
    answereds() {
      return this.stats ? this.stats.guests.data.filter(e => e.has_answers).length : 0
    },
  },
  async mounted() {
    this.$store.dispatch('requestForms/fetchId', this.$route.params.id)
    await this.$store.dispatch('requestForms/fetchFormUsers', { id: this.$route.params.id, filters: [] })
    this.$store.dispatch('requestForms/fetchStats', this.$route.params.id)

    await this.$store.dispatch('modals/fetchUserFields', 'view-request-forms')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'view-request-forms',
      })
    }
  },
  methods: {
    exportData(form, type, user) {
      Vue.prototype.$export({
        url: `${Vue.prototype.$groupUrl}/request-forms/${form}/export/${user}`,
        filters: { type },
        name: `request_forms_${type}`,
      })
    },
  },
}
</script>
